.two-columns
    columns: 2
    

.manifesto
    display: inline-block
    max-width: 500px
    font-weight: 100
    font-family: 'Roboto Condensed', sans-serif
    font-size: 1.5em


.contact-box
    // border: red 2px solid
    max-width: 350px
    float: left
    margin-right: 50px
    margin-bottom: 50px
    display: inline-block



.contact-container
    // border: pink 2px solid
