.modalLargeImage
    position: fixed
    z-index: 9999999
    border: white 40px solid
    width: 100vw
    height: 100vh
    left: 0
    top: 0
    right: 0 
    bottom: 0
    background: rgba(255, 255, 255, 0.95)
    // opacity: 0.95
    // transform: scale(0.95)
    margin: 0
    padding: 0

    .largeImageModal
        // border: blue 3px solid
        position: absolute
        left: 50%
        top: 50%
        transform: translate(-50%, -50%)
        width: 100%
        height: 100%
        object-fit: contain
        margin: 0
        padding: 0