@import "../styles/variables"

.pageContainer
    display: flex
    flex-direction: row
    flex-wrap: nowrap
    justify-content: flex-start
    align-items: flex-start
    // padding-right: 30vw
    // overflow: hidden
    // border: red 4px solid
    width: 1200px



    .projectpageInfo
        flex-grow: 1
            // float: left
        // border: blue 4px solid
        padding-top: 50px
        padding-left: 50px
        // width:500px

        // overflow-y: scroll
        // overflow-x: hidden

        position: -webkit-sticky /* for Safari */
        position: sticky
        top: 0
        align-self: flex-start /* <-- this is the fix */




    .imagePanel
        flex-grow: 3
        // float: left
        // width: 30vw
        // border: red 4px solid
        overflow-y: scroll
        overflow-x: hidden
        


        img
            max-height: 800px
            max-width: 100%
            float: right
            padding-top: 10px


    .title

        font-weight: 700
        font-size: 2em
        // position: relative
        display: inline-block


    .subtitle
        font-weight: 100
        font-size: 1.3em        
        // display: inline-block

    .projectinfo
        margin-top: 50px
        margin-bottom: 50px
        padding-top: 20px
        border-top: grey 1px solid
        border-bottom: grey 1px solid

 
            


        .key
            // display: inline
            font-weight: 500
            // font-size: 1.2em
        .value, .tagBox
            display: inline-block
            font-weight: 100

            margin-left: 2em
            margin-bottom: 1em
            margin-top: 0.2em

            a, h5, p
                font-size: inherit
                margin: 0
                padding: 0


        .tag, .comma, .tagBox
            display: inline-block
            
            
@include for-phone-only


    .pageContainer
        display: flex
        flex-direction: column
        flex-wrap: nowrap
        justify-content: flex-start
        align-items: flex-start

        width: 80vw
    
        .imagePanel
            order: 2
            // border: red 1px solid 
            padding-left: 50px

        .projectpageInfo
            // border: red 1px solid 
            width: 80vw
            order: 1
            position: relative
            


@include for-tablet

    .pageContainer
        display: flex
        flex-direction: column
        flex-wrap: nowrap
        justify-content: flex-start
        align-items: flex-start
        // border: red 3px solid 

        width: 50vw
    
        .imagePanel
            order: 2
            // border: red 1px solid 
            padding-left: 50px

        .projectpageInfo
            // border: red 1px solid 
            width: 100%
            order: 1
            position: relative
            

