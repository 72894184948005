@import "../styles/variables"


.whiteBG
    background: white
    overflow: hidden
    z-index: 100
    left: 0
    right: 0
    top: 0
    bottom: 0
    position: absolute
    // pointer-events: none

.pagewrapper
    overflow: hidden
    z-index: 100
    left: 0
    right: 0
    top: 0
    bottom: 0
    position: absolute


.logowrapper
    width: 100vw
    // border: red solid 3px
    height: 100vh

    
    .logo
        z-index: 100
        position: relative
        top: 50%
        left: 50%
        transform: translate(-50%, -50%)
        max-width: 100vw
        


.infowrapper
    // border: pink 5pt solid
    position: absolute
    top: 80%
    left: 50%
    transform: translate(-50%, -50%)

.column
    font-weight: 100
    font-size: 2em
    width: 600px
    float: left
    padding: 20px
    text-align: center
    

.backgrounds
    // border: blue 30px solid
    min-width: 100vw
    max-width: 100vw
    height: 100vh
    position: absolute
    left: 0
    top: 0
    box-sizing: border-box
    z-index: 2
    overflow: hidden

svg
    // border: 4px green solid
    box-sizing: border-box
    position: absolute
    left: 50%
    top: 50% 
    transform: translate(-50%, -50%)



.GREY
    stroke: $GREY 
.GREEN
    stroke: $GREEN
.YELLOW
    stroke: $YELLOW
.ORANGE
    stroke: $ORANGE
.RED
    stroke: $RED
.PINK
    stroke: $PINK
.PURPLE
    stroke: $PURPLE
.BLUE
    stroke: $BLUE
.MINT
    stroke: $MINT


$STROKEANIMATIONLENGTH: 2s
$STROKEANIMATIONDELAY: 0s
// $STROKEANIMATIONDELAY: 4.5s

$STROKELENGHT1: 1298
$STROKELENGHT2: 1400
$STROKELENGHT3: 1727
$STROKELENGHT4: 2430
$STROKELENGHT5: 1435
$STROKELENGHT6: 2149
$STROKELENGHT7: 1413
$STROKELENGHT8: 2672


.stroke
    fill: none
    stroke-width: 100
    stroke-miterlimit: 10
    // stroke: $RED 
    pointer-events: none



.one
    stroke-dasharray: $STROKELENGHT1
    stroke-dashoffset: $STROKELENGHT1
    animation: strokeanimation1 $STROKEANIMATIONLENGTH $STROKEANIMATIONDELAY*3 forwards linear

@keyframes strokeanimation1
    100%
        stroke-dashoffset: -$STROKELENGHT1

.two
    stroke-dasharray:$STROKELENGHT2
    stroke-dashoffset: $STROKELENGHT2
    animation: strokeanimation2 $STROKEANIMATIONLENGTH $STROKEANIMATIONDELAY*1 forwards linear

@keyframes strokeanimation2
    100%
        stroke-dashoffset: -$STROKELENGHT2


.three
    stroke-dasharray:$STROKELENGHT3
    stroke-dashoffset:$STROKELENGHT3
    animation: strokeanimation3 $STROKEANIMATIONLENGTH $STROKEANIMATIONDELAY*2 forwards linear

@keyframes strokeanimation3
    100%
        stroke-dashoffset: -$STROKELENGHT3


.four
    stroke-dasharray:$STROKELENGHT4
    stroke-dashoffset:$STROKELENGHT4
    animation: strokeanimation4 $STROKEANIMATIONLENGTH $STROKEANIMATIONDELAY*0 forwards linear

@keyframes strokeanimation4
    100%
        stroke-dashoffset: -$STROKELENGHT4

.five
    stroke-dasharray:$STROKELENGHT5
    stroke-dashoffset:$STROKELENGHT5
    animation: strokeanimation5 $STROKEANIMATIONLENGTH $STROKEANIMATIONDELAY*4 forwards linear

@keyframes strokeanimation5
    100%
        stroke-dashoffset: -$STROKELENGHT5

.six
    stroke-dasharray:$STROKELENGHT6
    stroke-dashoffset:$STROKELENGHT6
    animation: strokeanimation6 $STROKEANIMATIONLENGTH $STROKEANIMATIONDELAY*5 forwards linear

@keyframes strokeanimation6
    100%
        stroke-dashoffset: -$STROKELENGHT6
    
.seven
    stroke-dasharray:$STROKELENGHT7
    stroke-dashoffset:$STROKELENGHT7
    animation: strokeanimation7 $STROKEANIMATIONLENGTH $STROKEANIMATIONDELAY*6 forwards linear

@keyframes strokeanimation7
    100%
        stroke-dashoffset: -$STROKELENGHT7

.eight
    stroke-dasharray:$STROKELENGHT8
    stroke-dashoffset:$STROKELENGHT8
    animation: strokeanimation8 $STROKEANIMATIONLENGTH $STROKEANIMATIONDELAY*7 forwards linear

@keyframes strokeanimation8
    100%
        stroke-dashoffset: -$STROKELENGHT8





@include for-phone-only
    .infowrapper 
        .column
            font-size: 1em
