$GREY: #A0A0A0;
$BROWN: #998679;
$OLIVE: #A4BC7E;
$GREEN: #7BA084;
$YELLOW: #F2D678;
$ORANGE: #F2B48A;
$RED: #DD8079;
$PINK: #D49BB3;
$PURPLE: #9290B7;
$BLUE: #7CAEC3;
$MINT: #8CCFCF;
$TEAL: #75AFA8;

$MENU_BACKGROUND_COLOR: #ededed;
$FONT_COLOR: black;
$JOBS_NOTIFICATION_COLOR: $RED;
$ACTIVE_LINK_COLOR: $GREEN;









@mixin for-phone-only {
  @media (max-width: 599px) { @content; }
}
@mixin for-tablet {
  @media (min-width: 600px) and (max-width: 1599px) { @content; }
}

@mixin for-desktop {
  @media (min-width: 1600px) and (max-width: 2200px) { @content; }
}
@mixin for-big-desktop-up {
  @media (min-width: 2200px) { @content; }
}
