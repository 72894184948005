@import "./variables"


html, body
    // background-color: rgb(167, 167, 167)
    height: 100%
    width: 100%
    margin: 0
    padding: 0
    left: 0
    top: 0
    font-size: 100%

// SETTING FOR EVERYTHING AS DEFAULT
*
    color: $FONT_COLOR
    box-sizing: border-box
    text-decoration: none
    font-weight: 100
    font-family: 'Roboto Condensed', sans-serif
    // cursor: none !important

h1,h2, h3, strong
    font-weight: 500

h1
    margin-bottom: 0px

h2,h3
    font-size: 1.17em


::-webkit-scrollbar 
    width: 0  /* Remove scrollbar space */
    background: transparent  /* Optional: just make scrollbar invisible */



.body-wrapper
    position: fixed
    left: 0
    top: 0 
    right: 0
    bottom: 0
    overflow-x: hidden
    overflow-y: scroll

    &::before
        pointer-events: none
    

.content-box
    background: white
    padding-left: 300px
    display: flex
    justify-content: center
    flex-direction: column
    min-height: 100vh
    

@include for-phone-only
    .content-box
        padding: 30px
        margin-top: 10svh


@include for-big-desktop-up
    .content-box
        // border: red 50px solid
        max-width: 2800px
    


@include for-phone-only
    .c-cursor
        display: none
  
  
@media (hover: none)
    .c-cursor
        display: none
