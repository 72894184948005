@import "../styles/variables"

.siteheader
    // background: $MENU_BACKGROUND_COLOR
    z-index: 1
    position: fixed
    left: 0
    top: 0
    bottom: 0
    width: 200px
    padding-bottom: 50px
    display: flex
    flex-wrap: nowrap
    flex-direction: column
    justify-content: space-between
    align-items: center
    font-size: 1.2em
    font-weight: 300



    .foot
        font-size: 0.5em
    .logo
        margin-left: 15px
        margin-top: 15px
        margin-right: 15px

    .menu
        display: flex
        flex-wrap: nowrap
        flex-direction: column
        justify-content: space-between
        align-items: center
        position: relative
        width: 100%
        
    .menulink
        padding: 10px
        text-align: center
        margin: 10px
        transition: 1s ease

        &:hover
            font-weight: 900
            
        &.active
            color: $ACTIVE_LINK_COLOR
            font-weight: 900



    .language
        display: flex
        flex-wrap: nowrap
        flex-direction: row
        justify-content: space-between
        align-items: center
        margin-bottom: 50px


        .languageselector
            margin: 5px

    .jobNumber
        position: absolute
        top: 0
        right: 0
        transform: translateX(70%)
        background-color: $JOBS_NOTIFICATION_COLOR
        color: white
        height: 20px
        width: 20px
        overflow: hidden
        padding: 1px
        font-size: 0.8em
        border-radius: 100%
        text-align: center

.hamburger-react
    display: none



@include for-phone-only



    .siteheaderwrapper
        position: fixed
        z-index: 9999

    .siteheader
        // background: $MENU_BACKGROUND_COLOR
        background: white
        // border: solid 3px red
        z-index: 1
        position: fixed
        left: 0
        top: 0
        bottom: 0
        right: 0 
        width: 100svw
        height: 12svh 
            
        .jobNumber
            font-size: 0.4em
            
        .menu, .foot, .language
            display: none
        .logo
            // border: solid 3px red
            max-height: 10svh


        .menulink
            // border: red 2px solid 
            // width: 100svw
            height: 50px
            font-size: 2em


    .hamburger-react
        // border: blue 10px solid 
        display: inline-block
        position: absolute
        // padding: 25px
        right: 0
        top: 0 
        z-index: 9999

    .active
        height: 100svh   
        // background: red 
        z-index: 999
         
        .foot, .language
            display: block

        .menu
            display: flex


    