@import "../styles/variables"


.project-card
    // border: 4px yellow dotted
    // display: flex   
    margin-bottom: 50px
    height: 500px
    overflow: hidden
    margin-right: 100px
    transition: 1s ease



    &:first-child
        margin-top: 50px
        

    // &:hover .title
    //     font-size: 2em


    img
        max-height: 100%
        max-width: 100%
        object-fit: contain
        filter: grayscale(100%)
        opacity: 0.5
        transition: 1s ease


        // border: red 1px solid 
        
    &:hover img
        filter: grayscale(0%)
        opacity: 1





.imageContainer    
    float: left
    // border: 4px red dotted
    height: 100%


.descriptionContainer
    padding: 35px
    float: left
    text-align: right



    .title
        font-weight: 700
        font-size: 1.7em
        transition: 1s ease


    .subtitle
        font-weight: 300
        color: $GREY
        display: inline-block

    .year
        font-size: 2em
        color: $GREY

    .gap
        display: inline-block
        margin-left: 5px
        margin-right: 5px


    .tag
        font-weight: 300
        color: DarkGray
        display: inline-block
        
    .tagBox
        display: inline-block



@include for-phone-only
    .project-card
        margin: 0px

        // &:nth-child(odd) 
        .imageContainer
            float: left
        .descriptionContainer
            float: left
            text-align: left
            // border: red 40px solid



@include for-desktop
    .project-card
        height: 300px

        &:nth-child(odd) 
            .imageContainer
                float: right
            .descriptionContainer
                float: right
                text-align: left


@include for-tablet
    .project-card
        // border: red 3px solid
        // width: 100%
        height: 600px
        padding-right: 100px


        &:nth-child(odd) 
            .imageContainer
                float: right
            .descriptionContainer
                float: right
                text-align: left




        .imageContainer
            height: 400px
            position: absolute
            top: 0 
            left: 0
            right: 0 
            // border: blue 2px solid 

            img
                object-fit: cover
                height: 100%
                width: 100%


        .descriptionContainer
            height: 200px
            position: absolute
            bottom: 0 
            left: 0
            right: 0 
            // border: pink 2px solid 
            // text-align: center

            &:nth-child(odd) 
                text-align: left



@include for-big-desktop-up
    .project-card
        &:nth-child(odd) 
            .imageContainer
                float: right
            .descriptionContainer
                float: right
                text-align: left

